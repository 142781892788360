import { useDialogStore } from '@/stores/useDialogStore';
import type { ErrorIdent } from '@/@types/errorIdents';
import { useUserContext } from '@/stores/useUserContext';
import { useSecureSessionPost } from '@/composables/dataFetching/genericFetchers';
import type {
  Result,
  PostBody,
} from '@/server/api/[site]/user/account/editProfile.post';
import { handleLoadingError } from '~/utils/handleLoadingError';

export async function useEditProfile() {
  const dialogStore = useDialogStore();
  const { t } = useTrans();
  dialogStore.setHeadline(t('user.account.profile.editDialog.headline'));
  const userContext = useUserContext();

  const data = await userContext.loadAccountData();
  if (!data.canEdit) dialogStore.closeDialog();

  const customerTypeOptions = [
    {
      label: data.customerType.name,
      value: data.customerType.id,
    },
  ];

  const site = useSiteIdent();
  const errors = ref<ErrorIdent[]>([]);
  const isLoading = ref(false);
  interface editProfileForm {
    billingEmail: string;
    customerNumber: string;
    customerType: { label: string; value: string };
    firstName: string;
    lastName: string;
    phone: {
      phoneCountry: string;
      phoneNumber: string;
      fullPhoneNumber: string;
    };
    title: string;
    taxId: string;
    hasTaxId: boolean;
  }

  async function onSubmit(formData: editProfileForm) {
    if (data.canEdit) {
      isLoading.value = true;
      await editProfile(formData);
      isLoading.value = false;
    }
  }

  async function editProfile(formData: editProfileForm) {
    try {
      isLoading.value = true;
      const postData: PostBody = {};
      if (formData.billingEmail !== data.billingEmail)
        postData.billingEmail = formData.billingEmail;
      if (formData.firstName !== data.firstName)
        postData.firstName = formData.firstName;
      if (formData.lastName !== data.lastName)
        postData.lastName = formData.lastName;
      if (formData.title !== data.title) postData.title = formData.title;
      if (formData.phone.fullPhoneNumber !== data.phoneNumber) {
        postData.phoneNumber = formData.phone.fullPhoneNumber;
      }

      const result = await useSecureSessionPost<Result>(
        `/api/${site}/user/account/editProfile`,
        postData,
      );
      if (result) {
        await userContext.loadAccountData(true);
        dialogStore.closeDialog();
      }

      isLoading.value = false;
    } catch (e: any) {
      errors.value = e.data.data.errors;
      document.querySelector('.js-error-container')?.scrollIntoView({
        behavior: 'smooth',
      });
      isLoading.value = false;
      handleLoadingError(e);
    }
  }

  return {
    onSubmit,
    errors,
    customerTypeOptions,
    data,
    isLoading,
  };
}
